.top-app-bar {
    /* background: var(--color-surface); */
    height: 64px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 15000;
}

.top-app-bar-surface-tint {
    display: flex;
    align-items: center;
    height: inherit;
    width: inherit;
    padding: 0 1rem;
    transition: background-color 0.5s ease;
    /* background: var(--color-surface-elevation-0); */
    position: relative;
}

.top-app-bar-surface-tint-scroll {
    /* background: var(--color-surface-elevation-2); */
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 8%);
}

.top-app-bar-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.top-app-bar-title-scroll {
    opacity: 1;
}