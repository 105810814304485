/* .app{

} */

aside{ 
  display: none;
}

main{
  z-index: 10;
  padding: 3.5rem 0rem 7rem 0rem;
}

.page{
  margin: 0 1.5rem;
  margin-top: 0;
  animation-name: pagein;
  animation-duration: .3s;
}

@keyframes pagein {
  from {
    opacity: 0;
    margin-top: 24px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.app-fab{
  position: fixed;
  bottom: 96px;
  right: 16px;
  animation-name: in;
  animation-duration: .3s;
}

@keyframes in {
  from {
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (min-width: 600px) {

  aside{ 
    display: block;
    width: 80px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }

  main{
    z-index: 10;
    padding: 6rem 2rem 2rem 2rem;
    margin-left: 80px;
  }

  .app-fab{ display: none; }

}

@media only screen and (min-width: 960px) {

  .page{
    width: 480px;
    margin: auto;
  }

}

@media only screen and (min-width: 1240px) {

  aside{
    width: 360px;
  }

  main{
    z-index: 10;
    padding: 6rem 2rem 2rem 2rem;
    margin-left: 360px;
  }

}

@media only screen and (min-width: 1920px) {

  aside{
    width: 360px;
  }

}


/* EMAIL SENT */

.email-sent-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-surface-elevation-1);
  border-radius: var(--shape-corner-large);
  padding: 1.5rem;
  gap: 1rem;
  margin-top: 1rem;
}


.email-sent-card h2{
  text-align: center;
}




/* POLIZAS */

.polizas{
  margin: 1rem 1rem 4.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.poliza-card{
  background: var(--color-surface-elevation-1);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
}

.poliza-card h4{
  display: flex;
  align-items: center;
}

.poliza-card h6{
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.poliza-card p{
  margin: 1rem 0;
}