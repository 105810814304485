.phonebook{
    padding-bottom: 3.5rem;
}

.accordion {
    height: 3.5rem;
    background-color: inherit;
    color: var(--color-primary);
    color: var(--color-on-surface);
    padding: 0 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    text-align: left;
  }

  /* span{
    color: var(--color-on-surface);
  } */

  .accordion-icon{
    margin-right: 1rem;
}

  .accordion-expand{
      margin-left: auto;
  }
  
.accordion:hover {
    background-color: var(--blue-50); 
  }
  
  .panel {
    display: block;

  }

  .phonebook-item{
    align-items: center;
    height: 4.5rem;
    display: flex;
    padding: 0 1rem;
    color: var(--color-on-surface);
  }

  .phonebook-item:hover{
    color: var(--color-on-surface);
  }

  .phonebook-photo{
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      margin-right: 1rem;
  }

  .phonebook-action{
      margin-left: auto;
      color: var(--color-primary);
  }