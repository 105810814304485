.recreational{
    margin-top: 1rem;
}

.recreational-img{
    width: 100%;
    object-fit: cover;
}

.recreational-content{
    padding: 1.5rem;
}

.recreational p{
    margin: 1rem 0;
}