.container {
    background-color: aliceblue;
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 8%);
    border-radius: 2rem 2rem 0 0;
    padding: 1.5rem;
    /* display: flex; */
    /* flex-direction: column; */

    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 1.5rem;
    height: 100%;
    /* position: sticky;
    right: 0;
    top: 0; */
    position: relative;
    height: calc(100dvh - 4rem);
    position: fixed;
    z-index: 20000;
}

.progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.content {
    overflow-y: auto;
}

.input {
    display: flex;
    background: var(--color-surface-elevation-2);
    height: 48px;
    border-radius: 24px;
}

.input_text_field {
    flex-grow: 1;
    border: none;
    background: transparent;
    padding-left: .5rem;
    height: 100%;
    appearance: none;
    padding: .75rem;
}

/* MESSAGE */

.message {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* margin: 1rem 0; */
    animation: popIn 0.5s ease-in-out;
}

/* break word on .message p */
.message p {
    word-break: break-word;
}

/* fade in animation */
@keyframes popIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.message_me {
    flex-direction: row-reverse;
}

.message:not(:last-child) {
    margin-bottom: 1rem;
}


.message_avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--color-secondary-container);
}

.message_content {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */

    background-color: var(--color-secondary-container);
    color: var(--color-on-secondary-container);
    border-radius: 16px 16px 16px 0px;
    padding: 0.5rem 1rem;
    flex-grow: 1;
    position: relative;
}

.message_person_name {
    font-weight: bold;
    font-family: var(--typescale-label-large-font);
    font-weight: var(--typescale-label-large-weight);
    font-size: var(--typescale-label-large-size);
    line-height: var(--typescale-label-large-line-height);
    letter-spacing: var(--typescale-label-large-tracking);
}

.message_content_me {
    background-color: var(--color-primary);
    color: var(--color-on-primary);
    background-color: var(--color-primary-container);
    color: var(--color-on-primary-container);
    /* background-color: var(--color-tertiary-container);
    color: var(--color-on-tertiary-container); */
    border-radius: 16px 16px 0px 16px;
}


.message_date {
    font-family: var(--typescale-label-medium-font);
    font-weight: var(--typescale-label-medium-weight);
    font-size: var(--typescale-label-medium-size);
    line-height: var(--typescale-label-medium-line-height);
    letter-spacing: var(--typescale-label-medium-tracking);
    opacity: 0.5;
    /* margin-left: auto; */
}


.message_public {
    position: absolute;
    top: 1rem;
    right: 1rem;
    /* opacity: 0.5; */
}

.message_link {
    color: inherit;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.message_link:hover {
    text-decoration: underline;
}