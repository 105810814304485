.scrim{
    background-color: rgba(0, 0, 0, 0.0);
    height: 100vh;
    width: 100vw;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10010;
    opacity: 0;
    visibility: hidden;
    transition: visibility .3s, opacity .3s, background-color .3s;
  }
  
  .scrim-visible{
    background-color: rgba(0, 0, 0, 0.4);
    background: var(--color-on-surface-elevation-5);
    /* backdrop-filter: blur(10px); */
    opacity: 1;
    visibility: visible;
  }
  
  
  .modal-navigation-drawer{
    height: 100vh;
    width: calc(100vw - 56px);
    padding: 80px 12px 0 12px;
    position: fixed;
    left: -360px;
    top: 0;
    overflow-y: auto;
    border-radius: var(--shape-corner-large-end);
    background: var(--color-surface);
    transition: left .3s ease-in-out;
  }
  
  .modal-navigation-drawer-opened{
    left: 0px;
  }

  @media only screen and (min-width: 600px) {

    .modal-navigation-drawer{
      width: 360px;
    }
  
  }
  