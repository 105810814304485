.post-card {
  background: color-mix(in oklab, var(--color-background), var(--color-primary) 8%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--shape-corner-large);
  animation-name: postin;
  animation-duration: .3s;
}

.post-card:not(:last-child) {
  margin-bottom: 1.5rem;
}

@keyframes postin {
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.post-card-tint {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 160px auto;
  height: 100%;
  width: inherit;
  background: var(--color-surface-elevation-1);
  border-radius: inherit;
}

.post-card-img {
  border-radius: var(--shape-corner-large);
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
}

.post-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 1.5rem .75rem 1.5rem;
}