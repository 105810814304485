.navigation-bar{
    height: 80px;
    background: var(--color-surface);
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
}

.navigation-bar-surface-tint{
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    height: inherit;
    width: inherit;
    /* background: var(--color-surface-elevation-2); */
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 11%);
}

.navigation-bar-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0 1rem 0;
    gap: 0.25rem;
    color: var(--color-on-surface);
}

.navigation-bar-item:hover > .navigation-bar-active-indicator { background: var(--color-on-surface-elevation-2); }
.navigation-bar-item:focus > .navigation-bar-active-indicator { background:  var(--color-secondary-container); }
.navigation-bar-item:active > .navigation-bar-active-indicator { background: var(--color-on-surface-elevation-5); }

.navigation-bar-active-indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--shape-corner-large);
    height: 2rem;
    width: 4rem;
}

.navigation-bar-active-indicator-match{ background: var(--color-secondary-container); }

@media only screen and (min-width: 600px) {
    .navigation-bar{ display: none; }
}