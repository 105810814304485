.navigation-rail{ display: none; }

.navigation-rail-fab{
    margin-bottom: 3rem;
}

.navigation-rail-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0 1rem 0;
    gap: 0.25rem;
    font-family: var(--typescale-label-medium-font);
    font-weight: var(--typescale-label-medium-weight);
    font-size: var(--typescale-label-medium-size);
    line-height: var(--typescale-label-medium-line-height);
    letter-spacing: var(--typescale-label-medium-tracking);
    color: var(--color-on-surface);
}

.navigation-rail-item:hover > .navigation-rail-active-indicator { background: var(--color-on-surface-elevation-2); }
.navigation-rail-item:focus > .navigation-rail-active-indicator { background: var(--color-secondary-container); }
.navigation-rail-item:active > .navigation-rail-active-indicator { background: var(--color-on-surface-elevation-5); }

.navigation-rail-active-indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--shape-corner-large);
    height: 2rem;
    width: 4rem;
}

.navigation-rail-active-indicator-match{
    background: var(--color-secondary-container);
}

@media only screen and (min-width: 600px) {
    .navigation-rail{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 76px;
    }
}

@media only screen and (min-width: 1240px) {
    .navigation-rail{
        display: none;
    }
}