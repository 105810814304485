.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1.5rem;
}

.banner {
    height: 240px;
    width: 100%;
    margin: 1rem 0;
    object-fit: cover;
}

.content {
    padding: 0 1.5rem;
}

.event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: var(--color-primary);
    color: var(--color-on-primary);
    animation-name: eventIn;
    animation-duration: .3s;
}

@keyframes eventIn {
    from {
        opacity: 0;
        transform: translateY(16px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.event:not(:last-child) {
    margin-bottom: 1rem;
}

.feriado {
    background-color: var(--color-primary);
    color: var(--color-on-primary);
}

.pago {
    background-color: var(--color-secondary);
    color: var(--color-on-secondary);
}

.asamblea {
    background-color: var(--color-tertiary);
    color: var(--color-on-tertiary);
}

.celebracion {
    background-color: var(--color-tertiary);
    color: var(--color-on-tertiary);
}