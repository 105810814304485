.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    /* background-color: var(--color-secondary-container);
    color: var(--color-on-secondary-container); */
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 8%);
    /* height: 200px; */
    border-radius: 32px;
    margin: 1.5rem 0;
    padding: 1.5rem;
    gap: 1rem;
}

.banner {
    height: 240px;
    width: 100%;
    margin: 1rem 0;
    object-fit: cover;
}

/* CASO */

.casos_section {
    margin: 0 1.5rem;
}

.caso {
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 6%);
    padding: 1rem;
    border-radius: 16px;
    position: relative;
    margin: 1rem 0;
}

.tipo {
    /* margin-right: 6rem; */
}

.subscribe {
    position: absolute;
    top: .5rem;
    right: .5rem;
}


.proceso {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-align: right;
    margin-left: 6rem;
}