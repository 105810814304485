.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: var(--color-primary-container);
    color: var(--color-on-primary-container);
    border-radius: var(--shape-corner-large);
    padding: 1.5rem;
    margin: 1.5rem;
    text-align: center;
    min-height: 200px;
    box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.3);

    background: url('../assets/cardbackground.jpg');
    background-position: center;
    background-size: cover;

    color: white;
}

.carnet {
    display: flex;
    flex-direction: column;

    background: url('../assets/cardbackground.jpg');
    background-position: center;
    background-size: cover;

    border-radius: var(--shape-corner-large);

    gap: 2rem;

    color: white;
    padding: 1.5rem;
    margin: 1.5rem;
    min-height: 200px;
    box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.3);

}

.carnet-header {
    display: flex;
    justify-content: space-between;
}

.home-cards-section {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    width: 100dvw;
    /* margin-left: -1.5rem; */
}

.home-card:first-child {
    margin-left: 1.5rem;
}

.home-card {

    /* background: var(--color-secondary-container);
    color: var(--color-on-secondary-container); */

    background: color-mix(in oklab, var(--color-background), var(--color-primary) 11%);
    color: var(--color-primary);
    padding: .5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: .25rem;
    height: 140px;
    min-width: 140px;
}

.home-card:last-child {
    margin-right: 1.5rem;
}

.home-benefit-card {
    display: flex;
    background: var(--color-surface-elevation-1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--shape-corner-large);
    /* padding: 1rem; */
    margin: 1rem 0;
    color: var(--color-on-surface);
    padding: 1rem;
    gap: 1rem;
}

.home-benefit-card img {
    /* height: 100%; */
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: var(--shape-corner-large);
}

.home-benefit-card div {
    /* padding: 1rem; */
}