.signup-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-large);
    padding: 1.5rem;
    gap: 1rem;
    margin-top: 1rem;
}
  
.email-sent-card h2{
    text-align: center;
}

.signup-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
