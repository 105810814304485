.card {
    background: color-mix(in oklab, var(--color-background), var(--color-primary) 8%);
    padding: 1.5rem;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem 0;
}

.header {
    display: flex;
    justify-content: space-between;
}

.price {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}