.carne-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    /* background: var(--color-primary-container); */
    border-radius: var(--shape-corner-large);
    margin: 1rem 0;
    padding: 6rem 1.5rem;
    gap: 1rem;
}