* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  color: var(--color-on-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* VARIABLES */

:root {
  /* display - large */
  --typescale-display-large-font-family-name: Roboto, system-ui;
  --typescale-display-large-font-family-style: Regular;
  --typescale-display-large-font-weight: 500;
  --typescale-display-large-font-size: 57px;
  --typescale-display-large-line-height: 64px;
  --typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --typescale-display-medium-font-family-name: Roboto, system-ui;
  --typescale-display-medium-font-family-style: Regular;
  --typescale-display-medium-font-weight: 500;
  --typescale-display-medium-font-size: 45px;
  --typescale-display-medium-line-height: 52px;
  --typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --typescale-display-small-font-family-name: Roboto, system-ui;
  --typescale-display-small-font-family-style: Regular;
  --typescale-display-small-font-weight: 500;
  --typescale-display-small-font-size: 36px;
  --typescale-display-small-line-height: 44px;
  --typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --typescale-headline-large-font-family-name: Roboto, system-ui;
  --typescale-headline-large-font-family-style: Regular;
  --typescale-headline-large-font-weight: 500;
  --typescale-headline-large-font-size: 32px;
  --typescale-headline-large-line-height: 40px;
  --typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --typescale-headline-medium-font-family-name: Roboto, system-ui;
  --typescale-headline-medium-font-family-style: Regular;
  --typescale-headline-medium-font-weight: 500;
  --typescale-headline-medium-font-size: 28px;
  --typescale-headline-medium-line-height: 36px;
  --typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --typescale-headline-small-font-family-name: Roboto, system-ui;
  --typescale-headline-small-font-family-style: Regular;
  --typescale-headline-small-font-weight: 500;
  --typescale-headline-small-font-size: 24px;
  --typescale-headline-small-line-height: 32px;
  --typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --typescale-body-large-font-family-name: Roboto, system-ui;
  --typescale-body-large-font-family-style: Regular;
  --typescale-body-large-font-weight: 400;
  --typescale-body-large-font-size: 16px;
  --typescale-body-large-line-height: 24px;
  --typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --typescale-body-medium-font-family-name: Roboto, system-ui;
  --typescale-body-medium-font-family-style: Regular;
  --typescale-body-medium-font-weight: 400;
  --typescale-body-medium-font-size: 14px;
  --typescale-body-medium-line-height: 20px;
  --typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --typescale-body-small-font-family-name: Roboto, system-ui;
  --typescale-body-small-font-family-style: Regular;
  --typescale-body-small-font-weight: 400;
  --typescale-body-small-font-size: 12px;
  --typescale-body-small-line-height: 16px;
  --typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --typescale-label-large-font-family-name: Roboto, system-ui;
  --typescale-label-large-font-family-style: Medium;
  --typescale-label-large-font-weight: 500;
  --typescale-label-large-font-size: 14px;
  --typescale-label-large-line-height: 20px;
  --typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --typescale-label-medium-font-family-name: Roboto, system-ui;
  --typescale-label-medium-font-family-style: Medium;
  --typescale-label-medium-font-weight: 500;
  --typescale-label-medium-font-size: 12px;
  --typescale-label-medium-line-height: 16px;
  --typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --typescale-label-small-font-family-name: Roboto, system-ui;
  --typescale-label-small-font-family-style: Medium;
  --typescale-label-small-font-weight: 500;
  --typescale-label-small-font-size: 11px;
  --typescale-label-small-line-height: 16px;
  --typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --typescale-title-large-font-family-name: Roboto, system-ui;
  --typescale-title-large-font-family-style: Regular;
  --typescale-title-large-font-weight: 500;
  --typescale-title-large-font-size: 22px;
  --typescale-title-large-line-height: 28px;
  --typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --typescale-title-medium-font-family-name: Roboto, system-ui;
  --typescale-title-medium-font-family-style: Medium;
  --typescale-title-medium-font-weight: 500;
  --typescale-title-medium-font-size: 16px;
  --typescale-title-medium-line-height: 24px;
  --typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --typescale-title-small-font-family-name: Roboto, system-ui;
  --typescale-title-small-font-family-style: Medium;
  --typescale-title-small-font-weight: 500;
  --typescale-title-small-font-size: 14px;
  --typescale-title-small-line-height: 20px;
  --typescale-title-small-letter-spacing: 0.10px;

  --shape-corner-none: none;
  --shape-corner-extra-small: 4px;
  --shape-corner-extra-small-top: 4px 4px 0px 0px;
  --shape-corner-small: 8px;
  --shape-corner-medium: 12px;
  --shape-corner-large: 16px;
  --shape-corner-large-end: 0px 16px 16px 0px;
  --shape-corner-large-top: 16px 16px 0px 0px;
  --shape-corner-extra-large: 28px;
  --shape-corner-extra-large-top: 28px 28px 0px 0px;
  --shape-corner-full: 28px;

  /* --color-primary: #004dec;
  --color-on-primary: #ffffff;
  --color-primary-container: #dbe1ff;
  --color-on-primary-container: #001454;
  --color-secondary: #0062a2;
  --color-on-secondary: #ffffff;
  --color-secondary-container: #cfe4ff;
  --color-on-secondary-container: #001d36;
  --color-tertiary: #715d00;
  --color-on-tertiary: #ffffff;
  --color-tertiary-container: #ffe163;
  --color-on-tertiary-container: #231b00;
  --color-error: #B3261E;
  --color-on-error: #FFFFFF;
  --color-error-container: #F9DEDC;
  --color-on-error-container: #410E0B;
  --color-outline: #79747E66;
  --color-background: #fefbff;
  --color-on-background: #1b1b1f;
  --color-surface: #fefbff;
  --color-on-surface: #1b1b1f;
  --color-surface-variant: #E7E0EC;
  --color-on-surface-variant: #49454F;
  --color-inverse-surface: #303033;
  --color-inverse-on-surface: #f3f0f5; */

  --color-primary: #215fa6;
  --color-on-primary: #ffffff;
  --color-primary-container: #d5e3ff;
  --color-on-primary-container: #001c3b;
  --color-secondary: #00687b;
  --color-on-secondary: #ffffff;
  --color-secondary-container: #afecff;
  --color-on-secondary-container: #001f27;
  --color-tertiary: #865300;
  --color-on-tertiary: #ffffff;
  --color-tertiary-container: #ffddb8;
  --color-on-tertiary-container: #2b1700;
  --color-error: #ba1a1a;
  --color-error-container: #ffdad6;
  --color-on-error: #ffffff;
  --color-on-error-container: #410002;
  --color-background: #fdfbff;
  --color-on-background: #1a1c1e;
  --color-surface: #fdfbff;
  --color-on-surface: #1a1c1e;
  --color-surface-variant: #e0e2ec;
  --color-on-surface-variant: #43474e;
  --color-outline: #74777f;
  --color-inverse-on-surface: #f1f0f4;
  --color-inverse-surface: #2f3033;
  --color-inverse-primary: #a6c8ff;
  --color-shadow: #000000;
  --color-surface-tint: #215fa6;
  --color-outline-variant: #c4c6cf;
  --color-scrim: #000000;

  --color-neutral: #ffffff;

  --color-surface-elevation-0: #004dec00;
  --color-surface-elevation-1: #004dec0d;
  --color-surface-elevation-2: #004dec14;
  --color-surface-elevation-3: #004dec1c;
  --color-surface-elevation-4: #004dec1f;
  --color-surface-elevation-5: #004dec24;

  --color-on-surface-elevation-0: #1b1b1f00;
  --color-on-surface-elevation-1: #1b1b1f0d;
  --color-on-surface-elevation-2: #1b1b1f14;
  --color-on-surface-elevation-3: #1b1b1f1c;
  --color-on-surface-elevation-4: #1b1b1f1f;
  --color-on-surface-elevation-5: #1b1b1f24;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --color-primary: #b5c4ff;
    --color-on-primary: #002583;
    --color-primary-container: #0038b8;
    --color-on-primary-container: #dbe1ff;
    --color-secondary: #9acbff;
    --color-on-secondary: #003257;
    --color-secondary-container: #00497b;
    --color-on-secondary-container: #cfe4ff;
    --color-tertiary: #eac300;
    --color-on-tertiary: #3b2f00;
    --color-tertiary-container: #554500;
    --color-on-tertiary-container: #ffe163;
    --color-error: #F2B8B5;
    --color-on-error: #601410;
    --color-error-container: #8C1D18;
    --color-on-error-container: #F9DEDC;
    --color-outline: #938F9966;
    --color-background: #1b1b1f;
    --color-on-background: #e4e2e6;
    --color-surface: #1b1b1f;
    --color-on-surface: #e4e2e6;
    --color-surface-variant: #49454F;
    --color-on-surface-variant: #CAC4D0;
    --color-inverse-surface: #e4e2e6;
    --color-inverse-on-surface: #1b1b1f; */

    --color-primary: #a6c8ff;
    --color-on-primary: #003060;
    --color-primary-container: #004787;
    --color-on-primary-container: #d5e3ff;
    --color-secondary: #57d6f6;
    --color-on-secondary: #003641;
    --color-secondary-container: #004e5d;
    --color-on-secondary-container: #afecff;
    --color-tertiary: #ffb961;
    --color-on-tertiary: #472a00;
    --color-tertiary-container: #663e00;
    --color-on-tertiary-container: #ffddb8;
    --color-error: #ffb4ab;
    --color-error-container: #93000a;
    --color-on-error: #690005;
    --color-on-error-container: #ffdad6;
    --color-background: #1a1c1e;
    --color-on-background: #e3e2e6;
    --color-surface: #1a1c1e;
    --color-on-surface: #e3e2e6;
    --color-surface-variant: #43474e;
    --color-on-surface-variant: #c4c6cf;
    --color-outline: #8d9199;
    --color-inverse-on-surface: #1a1c1e;
    --color-inverse-surface: #e3e2e6;
    --color-inverse-primary: #215fa6;
    --color-shadow: #000000;
    --color-surface-tint: #a6c8ff;
    --color-outline-variant: #43474e;
    --color-scrim: #000000;

    --color-neutral: #000000;

    --color-surface-elevation-0: #b5c4ff00;
    --color-surface-elevation-1: #b5c4ff0d;
    --color-surface-elevation-2: #b5c4ff14;
    --color-surface-elevation-3: #b5c4ff1c;
    --color-surface-elevation-4: #b5c4ff1f;
    --color-surface-elevation-5: #b5c4ff24;

    --color-on-surface-elevation-0: #e4e2e600;
    --color-on-surface-elevation-1: #e4e2e60d;
    --color-on-surface-elevation-2: #e4e2e614;
    --color-on-surface-elevation-3: #e4e2e61c;
    --color-on-surface-elevation-4: #e4e2e61f;
    --color-on-surface-elevation-5: #e4e2e624;
  }
}

@media only screen and (min-width: 1920px) {

  :root {
    --typescale-body-medium-size: 1rem;
    --typescale-body-medium-line-height: 24px;
  }

}

/* SYMBOLS */

@font-face {
  font-family: "Material Symbols";
  src: url("/public/opt-material-symbols-rounded.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.material-symbols {
  font-family: "Material Symbols";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbols {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  transition: font-variation-settings 0.3s;
}

.active {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;

}

/* TYPOGRAPHY */

.display-large {
  font-family: var(--typescale-display-large-font-family-name);
  font-style: var(--typescale-display-large-font-family-style);
  font-weight: var(--typescale-display-large-font-weight);
  font-size: var(--typescale-display-large-font-size);
  letter-spacing: var(--typescale-display-large-letter-spacing);
  line-height: var(--typescale-display-large-line-height);
  text-transform: var(--typescale-display-large-text-transform);
  text-decoration: var(--typescale-display-large-text-decoration);
}

.display-medium {
  font-family: var(--typescale-display-medium-font-family-name);
  font-style: var(--typescale-display-medium-font-family-style);
  font-weight: var(--typescale-display-medium-font-weight);
  font-size: var(--typescale-display-medium-font-size);
  letter-spacing: var(--typescale-display-medium-letter-spacing);
  line-height: var(--typescale-display-medium-line-height);
  text-transform: var(--typescale-display-medium-text-transform);
  text-decoration: var(--typescale-display-medium-text-decoration);
}

.display-small {
  font-family: var(--typescale-display-small-font-family-name);
  font-style: var(--typescale-display-small-font-family-style);
  font-weight: var(--typescale-display-small-font-weight);
  font-size: var(--typescale-display-small-font-size);
  letter-spacing: var(--typescale-display-small-letter-spacing);
  line-height: var(--typescale-display-small-line-height);
  text-transform: var(--typescale-display-small-text-transform);
  text-decoration: var(--typescale-display-small-text-decoration);
}

.headline-large {
  font-family: var(--typescale-headline-large-font-family-name);
  font-style: var(--typescale-headline-large-font-family-style);
  font-weight: var(--typescale-headline-large-font-weight);
  font-size: var(--typescale-headline-large-font-size);
  letter-spacing: var(--typescale-headline-large-letter-spacing);
  line-height: var(--typescale-headline-large-line-height);
  text-transform: var(--typescale-headline-large-text-transform);
  text-decoration: var(--typescale-headline-large-text-decoration);
}

.headline-medium {
  font-family: var(--typescale-headline-medium-font-family-name);
  font-style: var(--typescale-headline-medium-font-family-style);
  font-weight: var(--typescale-headline-medium-font-weight);
  font-size: var(--typescale-headline-medium-font-size);
  letter-spacing: var(--typescale-headline-medium-letter-spacing);
  line-height: var(--typescale-headline-medium-line-height);
  text-transform: var(--typescale-headline-medium-text-transform);
  text-decoration: var(--typescale-headline-medium-text-decoration);
}

.headline-small {
  font-family: var(--typescale-headline-small-font-family-name);
  font-style: var(--typescale-headline-small-font-family-style);
  font-weight: var(--typescale-headline-small-font-weight);
  font-size: var(--typescale-headline-small-font-size);
  letter-spacing: var(--typescale-headline-small-letter-spacing);
  line-height: var(--typescale-headline-small-line-height);
  text-transform: var(--typescale-headline-small-text-transform);
  text-decoration: var(--typescale-headline-small-text-decoration);
}

.body-large {
  font-family: var(--typescale-body-large-font-family-name);
  font-style: var(--typescale-body-large-font-family-style);
  font-weight: var(--typescale-body-large-font-weight);
  font-size: var(--typescale-body-large-font-size);
  letter-spacing: var(--typescale-body-large-letter-spacing);
  line-height: var(--typescale-body-large-line-height);
  text-transform: var(--typescale-body-large-text-transform);
  text-decoration: var(--typescale-body-large-text-decoration);
}

.body-medium {
  font-family: var(--typescale-body-medium-font-family-name);
  font-style: var(--typescale-body-medium-font-family-style);
  font-weight: var(--typescale-body-medium-font-weight);
  font-size: var(--typescale-body-medium-font-size);
  letter-spacing: var(--typescale-body-medium-letter-spacing);
  line-height: var(--typescale-body-medium-line-height);
  text-transform: var(--typescale-body-medium-text-transform);
  text-decoration: var(--typescale-body-medium-text-decoration);
}

.body-small {
  font-family: var(--typescale-body-small-font-family-name);
  font-style: var(--typescale-body-small-font-family-style);
  font-weight: var(--typescale-body-small-font-weight);
  font-size: var(--typescale-body-small-font-size);
  letter-spacing: var(--typescale-body-small-letter-spacing);
  line-height: var(--typescale-body-small-line-height);
  text-transform: var(--typescale-body-small-text-transform);
  text-decoration: var(--typescale-body-small-text-decoration);
}

.label-large {
  font-family: var(--typescale-label-large-font-family-name);
  font-style: var(--typescale-label-large-font-family-style);
  font-weight: var(--typescale-label-large-font-weight);
  font-size: var(--typescale-label-large-font-size);
  letter-spacing: var(--typescale-label-large-letter-spacing);
  line-height: var(--typescale-label-large-line-height);
  text-transform: var(--typescale-label-large-text-transform);
  text-decoration: var(--typescale-label-large-text-decoration);
}

.label-medium {
  font-family: var(--typescale-label-medium-font-family-name);
  font-style: var(--typescale-label-medium-font-family-style);
  font-weight: var(--typescale-label-medium-font-weight);
  font-size: var(--typescale-label-medium-font-size);
  letter-spacing: var(--typescale-label-medium-letter-spacing);
  line-height: var(--typescale-label-medium-line-height);
  text-transform: var(--typescale-label-medium-text-transform);
  text-decoration: var(--typescale-label-medium-text-decoration);
}

.label-small {
  font-family: var(--typescale-label-small-font-family-name);
  font-style: var(--typescale-label-small-font-family-style);
  font-weight: var(--typescale-label-small-font-weight);
  font-size: var(--typescale-label-small-font-size);
  letter-spacing: var(--typescale-label-small-letter-spacing);
  line-height: var(--typescale-label-small-line-height);
  text-transform: var(--typescale-label-small-text-transform);
  text-decoration: var(--typescale-label-small-text-decoration);
}

.title-large {
  font-family: var(--typescale-title-large-font-family-name);
  font-style: var(--typescale-title-large-font-family-style);
  font-weight: var(--typescale-title-large-font-weight);
  font-size: var(--typescale-title-large-font-size);
  letter-spacing: var(--typescale-title-large-letter-spacing);
  line-height: var(--typescale-title-large-line-height);
  text-transform: var(--typescale-title-large-text-transform);
  text-decoration: var(--typescale-title-large-text-decoration);
}

.title-medium {
  font-family: var(--typescale-title-medium-font-family-name);
  font-style: var(--typescale-title-medium-font-family-style);
  font-weight: var(--typescale-title-medium-font-weight);
  font-size: var(--typescale-title-medium-font-size);
  letter-spacing: var(--typescale-title-medium-letter-spacing);
  line-height: var(--typescale-title-medium-line-height);
  text-transform: var(--typescale-title-medium-text-transform);
  text-decoration: var(--typescale-title-medium-text-decoration);
}

.title-small {
  font-family: var(--typescale-title-small-font-family-name);
  font-style: var(--typescale-title-small-font-family-style);
  font-weight: var(--typescale-title-small-font-weight);
  font-size: var(--typescale-title-small-font-size);
  letter-spacing: var(--typescale-title-small-letter-spacing);
  line-height: var(--typescale-title-small-line-height);
  text-transform: var(--typescale-title-small-text-transform);
  text-decoration: var(--typescale-title-small-text-decoration);
}

/* h1 {
  font-family: var(--typescale-headline-large-font);
  font-weight: var(--typescale-headline-large-weight);
  font-size: var(--typescale-headline-large-size);
  line-height: var(--typescale-headline-large-line-height);
  letter-spacing: var(--typescale-headline-large-tracking);
}

h2 {
  font-family: var(--typescale-headline-small-font);
  font-weight: var(--typescale-headline-small-weight);
  font-size: var(--typescale-headline-small-size);
  line-height: var(--typescale-headline-small-line-height);
  letter-spacing: var(--typescale-headline-small-tracking);
}

h3 {
  font-family: var(--typescale-title-large-font);
  font-weight: var(--typescale-title-large-weight);
  font-size: var(--typescale-title-large-size);
  line-height: var(--typescale-title-large-line-height);
  letter-spacing: var(--typescale-title-large-tracking);
}

h4 {
  font-family: var(--typescale-title-medium-font);
  font-weight: var(--typescale-title-medium-weight);
  font-size: var(--typescale-title-medium-size);
  line-height: var(--typescale-title-medium-line-height);
  letter-spacing: var(--typescale-title-medium-tracking);
}

h5 {
  font-family: var(--typescale-title-small-font);
  font-weight: var(--typescale-title-small-weight);
  font-size: var(--typescale-title-small-size);
  line-height: var(--typescale-title-small-line-height);
  letter-spacing: var(--typescale-title-small-tracking);
} */

p {
  font-family: var(--typescale-body-large-font-family-name);
  font-weight: var(--typescale-body-large-font-weight);
  font-size: var(--typescale-body-large-font-size);
  line-height: var(--typescale-body-large-line-height);
  letter-spacing: var(--typescale-body-large-tracking);
}

a {
  text-decoration: none;
  font-family: var(--typescale-body-medium-font);
  font-weight: var(--typescale-body-medium-weight);
  font-size: var(--typescale-body-medium-size);
  line-height: var(--typescale-body-medium-line-height);
  letter-spacing: var(--typescale-body-medium-tracking);
  -webkit-tap-highlight-color: transparent;
}

table {
  font-family: var(--typescale-body-medium-font);
  font-weight: var(--typescale-body-medium-weight);
  font-size: var(--typescale-body-medium-size);
  line-height: var(--typescale-body-medium-line-height);
  letter-spacing: var(--typescale-body-medium-tracking);
}

thead {
  font-family: var(--typescale-title-medium-font);
  font-weight: var(--typescale-title-medium-weight);
  font-size: var(--typescale-title-medium-size);
  line-height: var(--typescale-title-medium-line-height);
  letter-spacing: var(--typescale-title-medium-tracking);
}


/* COLORS */

.primary {
  background-color: var(--color-primary);
}

.primary-text {
  color: var(--color-primary);
}

.on-primary {
  background-color: var(--color-on-primary);
}

.on-primary-text {
  color: var(--color-on-primary);
}

.primary-container {
  background-color: var(--color-primary-container);
}

.primary-container-text {
  color: var(--color-primary-container);
}

.on-primary-container {
  background-color: var(--color-on-primary-container);
}

.on-primary-container-text {
  color: var(--color-on-primary-container);
}

.secondary {
  background-color: var(--color-secondary);
}

.secondary-text {
  color: var(--color-secondary);
}

.on-secondary {
  background-color: var(--color-on-secondary);
}

.on-secondary-text {
  color: var(--color-on-secondary);
}

.secondary-container {
  background-color: var(--color-secondary-container);
}

.secondary-container-text {
  color: var(--color-secondary-container);
}

.on-secondary-container {
  background-color: var(--color-on-secondary-container);
}

.on-secondary-container-text {
  color: var(--color-on-secondary-container);
}

.tertiary {
  background-color: var(--color-tertiary);
}

.tertiary-text {
  color: var(--color-tertiary);
}

.on-tertiary {
  background-color: var(--color-on-tertiary);
}

.on-tertiary-text {
  color: var(--color-on-tertiary);
}

.tertiary-container {
  background-color: var(--color-tertiary-container);
}

.tertiary-container-text {
  color: var(--color-tertiary-container);
}

.on-tertiary-container {
  background-color: var(--color-on-tertiary-container);
}

.on-tertiary-container-text {
  color: var(--color-on-tertiary-container);
}

.error {
  background-color: var(--color-error);
}

.error-text {
  color: var(--color-error);
}

.error-container {
  background-color: var(--color-error-container);
}

.error-container-text {
  color: var(--color-error-container);
}

.on-error {
  background-color: var(--color-on-error);
}

.on-error-text {
  color: var(--color-on-error);
}

.on-error-container {
  background-color: var(--color-on-error-container);
}

.on-error-container-text {
  color: var(--color-on-error-container);
}

.background {
  background-color: var(--color-background);
}

.background-text {
  color: var(--color-background);
}

.on-background {
  background-color: var(--color-on-background);
}

.on-background-text {
  color: var(--color-on-background);
}

.surface {
  background-color: var(--color-surface);
}

.surface-text {
  color: var(--color-surface);
}

.on-surface {
  background-color: var(--color-on-surface);
}

.on-surface-text {
  color: var(--color-on-surface);
}

.surface-variant {
  background-color: var(--color-surface-variant);
}

.surface-variant-text {
  color: var(--color-surface-variant);
}

.on-surface-variant {
  background-color: var(--color-on-surface-variant);
}

.on-surface-variant-text {
  color: var(--color-on-surface-variant);
}

.outline {
  background-color: var(--color-outline);
}

.outline-text {
  color: var(--color-outline);
}

.inverse-on-surface {
  background-color: var(--color-inverse-on-surface);
}

.inverse-on-surface-text {
  color: var(--color-inverse-on-surface);
}

.inverse-surface {
  background-color: var(--color-inverse-surface);
}

.inverse-surface-text {
  color: var(--color-inverse-surface);
}

.inverse-primary {
  background-color: var(--color-inverse-primary);
}

.inverse-primary-text {
  color: var(--color-inverse-primary);
}

.shadow {
  background-color: var(--color-shadow);
}

.shadow-text {
  color: var(--color-shadow);
}

.surface-tint {
  background-color: var(--color-surface-tint);
}

.surface-tint-text {
  color: var(--color-surface-tint);
}

.outline-variant {
  background-color: var(--color-outline-variant);
}

.outline-variant-text {
  color: var(--color-outline-variant);
}

.scrim {
  background-color: var(--color-scrim);
}

.scrim-text {
  color: var(--color-scrim);
}

/* BUTTONS */

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  position: relative;
  cursor: pointer;
  font-family: var(--typescale-label-large-font-family-name);
  font-weight: var(--typescale-label-large-font-weight);
  font-size: var(--typescale-label-large-font-size);
  letter-spacing: var(--typescale-label-large-letter-spacing);
  line-height: var(--typescale-label-large-line-height);
  height: 2.5rem;
  -webkit-tap-highlight-color: transparent;
}

/* FILLED BUTTON */

.filled-button {
  padding: 0 24px;
  color: var(--color-on-primary);
  background: var(--color-primary);
  border-radius: 1.25rem;
  transition: box-shadow ease 0.3s;
}

.filled-button:disabled {
  opacity: 0.5;
}

.filled-button:hover {
  background-color: color-mix(in oklab, var(--color-primary), var(--color-background) 5%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.filled-button:focus {
  background-color: color-mix(in oklab, var(--color-primary), var(--color-background) 14%);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}

.filled-button:active {
  background-color: color-mix(in oklab, var(--color-primary), var(--color-background) 11%);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}

.filled-button svg {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

.filled-button span {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

/* FILLED TONAL BUTTON */

.filled-tonal-button {
  padding: 0 24px;
  color: var(--color-on-secondary-container);
  background: var(--color-secondary-container);
  border-radius: 1.25rem;
  transition: box-shadow ease 0.3s;
}

.filled-tonal-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.filled-tonal-button:active {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}

.filled-tonal-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--color-on-secondary-container);
  opacity: 0;
  transition: opacity ease 0.3s;
}

.filled-tonal-button:hover::after {
  opacity: 0.05;
}

.filled-tonal-button:focus::after {
  opacity: 0.14;
}

.filled-tonal-button:active::after {
  opacity: 0.11;
}

.filled-tonal-button svg {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

.filled-tonal-button span {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

/* OUTLINED BUTTON */

.outlined-button {
  padding: 0 24px;
  color: var(--color-primary);
  background: transparent;
  border-radius: 1.25rem;
  border: 1px solid var(--color-outline);
  transition: box-shadow ease 0.3s;
}

.outlined-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--color-primary);
  opacity: 0;
  transition: opacity ease 0.3s;
}

.outlined-button:hover::after {
  opacity: 0.05;
}

.outlined-button:focus::after {
  opacity: 0.14;
}

.outlined-button:active::after {
  opacity: 0.11;
}

.outlined-button:disabled {
  opacity: 0.3;
}

.outlined-button svg {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

.outlined-button span {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

.outlined-button img {
  height: 18px;
  width: 18px;
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

/* TEXT BUTTON */

.text-button {
  padding: 0 12px;
  color: var(--color-primary);
  background: inherit;
  border-radius: 1.25rem;
}

.text-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--color-primary);
  opacity: 0;
  transition: opacity ease 0.3s;
}

.text-button:hover::after {
  opacity: 0.05;
}

.text-button:focus::after {
  opacity: 0.14;
}

.text-button:active::after {
  opacity: 0.11;
}

.text-button span {
  margin-right: 0.25rem;
  margin-left: -0.5rem;
}

/* ICON BUTTON */

.icon-button {
  height: 40px;
  width: 40px;
  /* margin: 4px; */
  border-radius: 24px;
  color: var(--color-on-background);
  background: transparent;
  z-index: 10000;
}

.icon-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--color-primary);
  opacity: 0;
  transition: opacity ease 0.3s;
}

.icon-button:hover::after {
  opacity: 0.05;
}

.icon-button:focus::after {
  opacity: 0.14;
}

.icon-button:active::after {
  opacity: 0.11;
}

/* INPUT */

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: inherit;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: inherit;
}

.input-container {
  position: relative;
}

.input-normal {
  height: 2.5rem;
  height: 3rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid var(--color-outline);
  border-radius: var(--shape-corner-extra-small);
  font-family: var(--typescale-body-large-font);
  font-weight: var(--typescale-body-large-weight);
  font-size: var(--typescale-body-large-size);
  line-height: var(--typescale-body-large-line-height);
  letter-spacing: var(--typescale-body-large-tracking);
  color: var(--color-on-surface);
}

.input-normal:focus {
  border: 2px solid var(--color-on-primary-container);
}

.input-normal:hover:not(:focus) {
  border-color: var(--color-surface-variant);
}

.input-label {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1rem;
  transition: top 0.2s, font-size 0.2s;
  background-color: var(--color-surface);
  border-color: var(--color-on-surface);
  padding: 0 0.25rem;
}

.input-normal:focus+.input-label {
  top: -0.5rem;
  font-size: 0.875rem;
  color: var(--color-primary);
}

.input-normal:not([value=""]):not(:focus)+.input-label {
  top: -0.5rem;
  font-size: 0.875rem;
  color: #5f6368;
}

.input-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--gray-500);
  cursor: pointer;
}

/* TABLE */

.table-container {
  background: var(--color-surface);
  overflow-x: auto;
  margin: 2rem 0;
}

table {
  width: 100%;
  background: var(--color-surface-elevation-1);
  color: var(--color-on-primary-container);
  border-radius: var(--shape-corner-large);
  border-collapse: separate;
}

thead tr {
  height: 56px;
}

th {
  padding: 0 1rem;
  background: red;
  background: var(--color-surface-elevation-2);
}

th:first-child {
  border-radius: 16px 0 0 0;
}

th:last-child {
  border-radius: 0 16px 0 0;
}

tbody tr {
  height: 48px;
}

tbody tr:hover {
  background: var(--color-surface-elevation-1);
}

td {
  padding: 1rem 1rem;
}


#middle {
  border-radius: 16px;
  overflow: auto;
}

@media only screen and (min-width: 600px) {

  th {
    padding: 0 2rem;
  }

  td {
    padding: 1rem 2rem;
  }
}