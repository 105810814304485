.convenio-card{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem 0;
    border: 1px solid var(--color-outline);
    padding: 1.5rem;
    border-radius: var(--shape-corner-large);
    position: relative;
    animation-name: convenioin;
    animation-duration: .3s;
}

@keyframes convenioin {
from {
  opacity: 0;
  transform: translateY(16px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

.convenio-logo{
    height: 56px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border-radius: var(--shape-corner-large);
}